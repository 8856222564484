<template>
  <v-card>
      <v-card-title :class="`${color}--text`">
          <v-icon v-if="icon" class="mx-1" :color="color">{{ icon }}</v-icon>
          {{ title | t }} | {{ 'select_a_file' | t }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 450px;" class="ma-0 pa-0">
          
          <v-sheet 
          v-if="!fields"
          class="fh fw d-flex  align-center justify-center px-6 " >
          <span>

            <v-file-input
                rounded
                clearable
                filled
                @click:clear="clear"
                :label="$t('select_a_file')"
                accept=".xlsx, .xls, .csv"
                @change="handelFile"
                :error="!!error"
                :error-messages="error"
                persistent-hint
                :hint="$t('select_import_file', '.xlsx, .xls, .csv')"
            ></v-file-input>
                    <v-subheader>
                        {{ 'example' | t }}
                    </v-subheader>
                    <img src="@/assets/example.png" width="100%" style="max-width: 350px; opacity:0.8" />
          </span>
          </v-sheet>
            <v-sheet
                v-else
            
            >
                <v-alert dense text color="info" icon="mdi-information">
                    {{'select_field_for_value' | t}}
                </v-alert>
                <v-subheader>
                    {{ 'required_fields' | t }}
                    <v-spacer></v-spacer>
                </v-subheader>
                    <v-chip small v-for="(f,n) in requiredFields" 
                    :color="selected.includes(f.value) ? 'success' : 'error'"
                    :key="n" class="mx-1">
                         <v-icon  small class="mx-1">{{
                            selected.includes(f.value) ? 'mdi-check' : 'mdi-close'
                        }}</v-icon>
                        {{f.text}}  
                    </v-chip>
                <v-data-table
                    disable-sort
                    :headers="headers"
                    hide-default-footer
                    :items="tableItems"
                    mobile-breakpoint="450"
                >
                    <template v-slot:item.field="{item}">
                        <strong>
                            {{item.field}}
                        </strong>
                    </template>
                    <template v-slot:item.select="{item}">
                        <v-select
                            outlined
                            dense
                            rounded
                                :value="map[item.field]"
                                @input="setField($event, item.field)"
                                :items="fieldsToSelect(item)"
                                hide-details
                                height="10"
                                style="font-size: .8rem;"
                            >

                            </v-select>
                    
                    </template>
                </v-data-table>
            </v-sheet>


    

      </v-card-text>
      <v-divider></v-divider>
      <v-progress-linear :value="progress"  v-if="data" ></v-progress-linear>
      <v-card-actions>
          
          <v-btn :color="color" rounded outlined @click="confirm" :disabled="!valid" :loading="loading">
              <v-icon small>{{ confirmIcon}}</v-icon>
              {{ confirmText | t }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn  outlined rounded @click="cancel" :disabled="loading">
              <v-icon small >{{ cancelIcon }}</v-icon>
              {{ cancelText | t }}
          </v-btn>
      </v-card-actions>
  </v-card>
</template>

<script>
import XLSX from 'xlsx' 
export default {
    props: {
        name: String,
        title: String,
        text: String,
        icon: String,
        color: {
            type: String,
            default: 'success'
        },
        confirmText: {
            type: String,
            default: 'import'
        },
        confirmIcon: {
            type: String,
            default: 'mdi-database-import'
        },
        cancelText: {
            type: String,
            default: 'cancel'
        },
        cancelIcon: {
            type: String,
            default: 'mdi-close'
        },
        loading: Boolean,
        mapFields: Array
    },
    data: vm => ({
        error: '',
        map: {},
        fields: null,
        data: null,
        progress: 0,
        indeterminate: false,
        headers: [
            { text: vm.$t('file_field'), value: 'field', width:150,  class: ["font-weight-bold"]},
            { text:  vm.$t('field'), value: 'select', width: 200},
            {text: vm.$t('values'), value: '0'},
            {text: '', value: '1'},
            {text: '', value: '2'},
            {text: '', value: '3'},
            {text: '', value: '4'}
        ]
    }),
    computed: {
        valid () {
            const { length } = this.requiredFields.filter(f => this.selected.includes(f.value))
            return length == this.requiredFields.length
        },
        requiredFields () {
            return this.mapFields.filter(f => f.required )
        },
        selected () {
            return Object.values(this.map)
        },
        tableItems () {
            const { fields, data  } = this
            if (!fields || !data ) return []
            const items = []
            fields.forEach((field, ind) => {
                items[ind] = {
                    field,
                    select: ind
                }
                   
                for (let index = 0; index < (data.length < 5 ? data.length : 5); index++) {
                    items[ind][index] = data[index][field]
                    
                }
            })
         
            return items
        },
    },
    methods: {
        fieldsToSelect(item) {
            const fields = this.mapFields.filter(f => {
                if (f.value == this.map[item.field]) return true
                return !this.selected.includes(f.value)
            })
            return [
                { text: this.$t('please_select'), value: undefined },
                ...fields
                ] 

        },
        setField(value, field) {
            
            if (!value) {
                this.$delete(this.map, field)
            }
            else
                this.$set(this.map, field, value)
        },
        parseExcel (file) {
            this.error = ''
            const vm = this
             vm.dialogLoading(true)
             vm.indeterminate = true
            const reader = new FileReader();

            reader.onload = function(e) {
                const data = e.target.result;
            
                const workbook = XLSX.read(data, {
                    type: 'binary'
                });
            
                workbook.SheetNames.forEach(function(sheetName) {
                    // Here is your object
                    const documents = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
             
                    if (!documents.length) {
                        vm.dialogLoading(false)
                        vm.error = vm.$t(`import_file_empty`)
                        vm.notify(vm.error, {
                                color: 'error',
                                icon: 'mdi-table-alert'
                        })
                        return
                    }
                    const [first] = documents
                    vm.fields = Object.keys(first)
                    vm.data = documents
                })
                vm.indeterminate = false
                vm.dialogLoading(false)

            };

            reader.onerror = this.handelError

            reader.readAsBinaryString(file);
        }, 
        handelFile (file) {
            if (file)
                this.parseExcel(file)
        },
        clear () {
            this.fields= null,
            this.data= null
        },
        confirm () {
            const { data, map  } = this
            this.$emit('confirm', { data, map, dialog: this })
        },
        cancel () {
            this.$emit('cancel')
        }
    }
}
</script>

<style>

</style>